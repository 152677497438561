/* -- preferences css -- */
* {
  padding: 0;
  margin: 0;
  list-style: none;
}

body {
  background-color: #f5f6f8;
}

.container {
  max-width: 100%;
  padding: 0 20px;
}

.main {
  width: 100%;
  min-height: calc(-70px + 100vh);
  background-color: #f5f6f8;
  position: relative;
  z-index: 9;
}

.dashboard_tittle_box h3 {
  font-size: 22px;
  color: #000000;
  font-weight: 600;
  padding-top: 20px;
}

.dashboard_tittle_box h6 {
  font-size: 16px;
  color: #676b6fe3;
  font-weight: 600;
}

.dashboard_tittle_box span {
  padding-left: 25px;
}

.tabs_wrap {
  background-color: #fff !important;
  margin: 15px 0px;
  padding-bottom: 50px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 2px solid #143e3c !important;
  background-color: transparent;
  color: #143e3c !important;
  font-weight: 600;
  font-size: 20px;
  border: none;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
  isolation: unset;
  cursor: pointer;
}

.tab-content-wrapper {
  margin: 2px auto;
}

/* .row {
  margin-left: 2px !important;
} */
.preference_table {
  padding-top: 15px;
}

.no-data-content {
  padding: 10em;
}

.react-tabs__tab,
.react-tabs__tab:focus,
.react-tabs__tab:hover {
  /* display: inline-block;
  /* border: 1px solid transparent; */
  /* border-bottom: none; */
  /*bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px; */
  cursor: pointer;
  color: #143e3c !important;
  font-weight: 600;
  font-size: 20px;
}

.nav-item li.react-tabs__tab--selected {
  border: none !important;
}

li.react-tabs__tab--selected .nav-link {
  color: #143e3c !important;
  font-weight: 600;
  border: none;
}

li.react-tabs__tab--selected .nav-link:focus-visible {
  border: 0;
  box-shadow: 0;
}

.nav-link {
  color: #143e3c !important;
}

.react-tabs__tab--selected,
.react-tabs__tab--selected:focus,
.react-tabs__tab--selected:hover {
  border-bottom: 2px solid #143e3c !important;
  background-color: transparent !important;
  color: #143e3c !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  outline: none !important;
}

.tabs_wrap a {
  color: #6c757d !important;
  font-weight: 600;
  font-size: 20px;
}

.tabs_wrap a:hover {
  color: #6c757d;
}

.nav-tabs {
  border: none;
  margin-top: 5px;
}

.tab-content > .tab-pane {
  padding-top: 15px;
}

.outer_wrap {
  display: flex;
  padding-top: 15px;
  padding-bottom: 10px;
}

.do_you_link_left {
  max-width: 80px;
  width: 100%;
}

.img-pref {
  align-items: center;
  object-fit: cover;
  max-width: 55px;
  width: 100%;
  border-radius: 20px;
  height: 55px;
  background-color: #cbe2e1;
}

.do_you_link_right label {
  margin-bottom: 0;
  color: #6c757d;
  font-size: 18px;
  padding-left: 5px;
}

.do_you_link_right p {
  color: #6c757d;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.text-right {
  text-align: right;
}

.like_wrap {
  border-bottom: 1px solid #dee2e6;
}

.mc-preference-checks .form-check-label {
  padding-left: 0px;
}

.mc-preference-checks .form-check {
  padding-left: 0px;
}

.delete_box {
  padding-top: 20px;
}

.copywrite h1 {
  color: #98a6ad;
  font-size: 15px;
  text-align: center;
  margin: 30px 0px;
  font-weight: 600;
}

.multi_choice input {
  height: 18px;
  width: 18px;
}

.multi_choice .do_you_link_right p {
  margin-bottom: 10px;
}

.multi_choice .form-check-label {
  padding-left: 0px;
  margin-top: -3px;
}

.multi_choice .form-check .form-check-input {
  margin-right: 10px;
}

.multi_choice .form-check .form-check-input[type="radio"] {
  height: 20px;
}

.multi_choice .form-check {
  padding-top: 0;
}

.form-check-input:checked {
  background-color: #04736d;
  border-color: #04736d;
}

.form-check .form-check-input {
  margin-left: 0px;
}

.form-check-input:focus {
  box-shadow: none;
}

.free_flow .do_you_link_right p {
  padding-top: 20px;
}

.free_flow .outer_wrap {
  padding-bottom: 20px;
}

.nav-tabs .nav-link {
  border: 0;
  font-size: 18px;
}

.multi_choice .outer_wrap {
  padding-bottom: 10px;
}

.add_compant_btn {
  text-align: right;
}

/* popup CSS */
.show_popup,
.show_popup_1 {
  display: none;
  background: transparent;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.07);
}

.Preference_edit_modal .poup_data {
  background: #fff;
  position: absolute;
  top: 168px;
  right: 34px;
  width: 100%;
  max-width: 540px;
  padding: 20px;
  border-radius: 5px;
  z-index: 9;
  text-align: left;
  padding-bottom: 13%;
}

.Preference_edit_modal .poup_data h1 {
  color: #000000;
  font-size: 21px;
  font-weight: 600;
  padding-bottom: 6px;
  padding-top: 26px;
}

.Preference_edit_modal .poup_data p {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #70707061;
}

.Preference_edit_modal .submit_preference {
  border: none;
  padding: 10px 50px;
  color: #fff;
  margin-top: 25px !important;
  background-color: #143e3c;
  text-align: center;
  display: table;
  font-weight: 600;
}

.Preference_edit_modal h1 {
  color: #000000;
  font-size: 22px;
  text-align: left;
  font-weight: 600;
}

.pr-8 {
  padding-right: 8px !important;
}

.Preference_edit_modal .img_change_div {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 10px;
}

.Preference_edit_modal span {
  color: #000000;
  font-size: 18px;
  text-align: left;
  padding: 20px 0px;
  display: inline-block;
}

.Preference_edit_modal .free_flow_input input {
  width: 100%;
  padding: 10px;
  font-weight: 600;
  color: #000;
  border-radius: 5px;
  border: 1px solid #00000061;
}

.free_flow_input input::placeholder {
  color: black;
  opacity: 1;
  /* Firefox */
}

.Preference_edit_modal .close_modal_box .close_icon {
  width: 3%;
  padding-right: 2px;
  cursor: pointer;
}

.Preference_edit_modal .close_modal_box a {
  font-size: 16px;
  color: #6c757d;
  font-weight: 600;
}

.Preference_edit_modal .close_modal_box a:hover {
  color: #6c757d;
  cursor: pointer;
}

.edit_img,
.delete_img {
  cursor: pointer;
}

/* popup Css ends */

/* Add preference button  modal css start */

.add_preference_modal .poup_data h1 {
  color: #000000;
  font-size: 21px;
  font-weight: 600;
  padding-bottom: 6px;
  padding-top: 20px;
}

.add_preference_modal .poup_data {
  background: #fff;
  position: absolute;
  top: 50%;
  right: auto;
  width: 100%;
  max-width: 701px;
  padding: 20px;
  border-radius: 5px;
  z-index: 9;
  text-align: left;
  padding-bottom: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.add_compant_btn .add_preference_modal .accordion-button {
  margin-top: 0px !important;
  background-color: transparent;
  color: #000000;
}

.add_preference_modal .accordion-button:focus {
  box-shadow: none;
  border-color: none;
}

.category_of_preferences_box {
  display: flex;
}

.category_of_preferences_left {
  width: 48%;
  float: left;
}

.category_of_preferences_right {
  /* width: 52%; */
  float: left;
  text-align: right;
  position: absolute;
  right: 15px;
  top: 35px;
  z-index: 9999;
}

.category_content_left_box {
  width: 100%;
  float: left;
  border: 1px solid #70707061;
  border-radius: 20px;
  max-width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category_content_right_box {
  /* width: 65%; */
  float: left;
  margin-left: 5%;
}

.category_content_right_box span {
  padding: 5px 0px 6px;
}

.category_content_right_box h5 {
  color: #02736d;
  font-size: 18px;
  padding-bottom: 4px;
  font-weight: 500;
}

.category_content_right_box h6 {
  font-size: 14px;
  font-weight: 600;
}

.category_of_preferences_right .add {
  background-color: #143e3c;
  color: #fff;
  border-radius: 5px;
  padding: 8px 20px;
  margin-right: 35px;
  margin-bottom: 20px;
  margin-top: -10px;
}

.add-subcat-buttons .action_button {
  background-color: #143e3c;
  color: #fff;
  border: none;
  border-radius: 0;
  padding: 2px 10px;
  margin-right: 15px;
  margin-bottom: 20px;
  /* margin-top: -10px; */
  width: 70px;
}

.save-button {
  background-color: #143e3c;
  color: #fff;
  border-radius: 0;
  padding: 2px 10px;
  margin-top: 24px;
  margin-right: 0;
  width: 70px;
  border-width: 0 !important;
}

.w-40 {
  width: 40% !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.add_preference_modal .accordion-body {
  background-color: #e2efee3b;
  border-left: 1px solid #00000040;
  border-right: 1px solid #00000040;
  border-bottom: 1px solid #00000040;
}

.accordion-body .food_category_buttons {
  display: flex;
  flex-wrap: wrap;
}

.accordion-body h2 {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 14px;
}

.food_category_buttons label {
  padding: 8px 15px;
  color: #02736d;
  background-color: #e2efee;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 7px;
  margin-top: 3px;
  cursor: pointer;
}

.show-deleteicon {
  display: inline-flex !important;
}

.food_category_buttons label:after {
  content: "";
  background-image: url("../images/cancel.png");
  width: 15px;
  height: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  left: 5px;
  top: 2px;
  right: 0px;
  position: relative;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion-header {
  border: 1px solid #00000040;
}

#accordionExample {
  overflow-y: auto;
  height: 650px;
  scrollbar-color: #fefdff white;
}

.accordion-button.collapsed .add {
  display: none;
}

.pr-10 {
  padding-right: 10px !important;
}

.accordion-button::after {
  position: absolute;
  top: 25px !important;
  right: 20px;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.category_of_preferences_right .preference_save {
  background-color: #143e3c;
  color: #fff;
  border-radius: 5px;
  padding: 6px 30px;
  margin-right: 10px;
  margin-bottom: 20px;
  margin-top: -10px;
}

.accordion-button.collapsed .preference_save {
  display: none;
}

/* Add preference button  modal css end */

.select-lg {
  height: 40px;
  width: 20vw;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  padding-left: 15px;
  margin-top: 25px;
  margin-bottom: 10px;
  margin-right: 15px;
}

.add_preference_btn {
  text-align: right;
  background: #097b75;
  color: #fff;
  font-size: 14px;
  border: none;
  padding: 10px 15px;
  font-weight: 500;
  margin-top: 25px;
}

/* save popup css starts */

.show_save_popup {
  display: none;
  background: #09080830;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
}

.save_poup_data {
  background: #fff;
  position: absolute;
  top: 50%;
  right: auto;
  width: 100%;
  max-width: 630px;
  padding: 20px;
  border-radius: 5px;
  z-index: 9;
  text-align: left;
  padding-bottom: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.show_save_popup .close_icon {
  width: 3%;
  padding-right: 2px;
  cursor: pointer;
}

.save_poup_data a:hover {
  /* color: #6C757D !important; */
  cursor: pointer;
}

.save_poup_data a {
  color: #6c757d !important;
  cursor: pointer;
}

.save_poup_data h1 {
  font-size: 20px;
  font-weight: 600;
  padding-top: 18px;
}

.update_img img {
  padding-top: 15px;
  padding-bottom: 18px !important;
}

.nav_li {
  padding-right: 65px;
}

.nav_ul {
  border-bottom: 2px solid #6c757d4d;
}

.question_tittle_box h6 {
  color: #6c757d;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  padding-top: 18px;
}

.question_tittle_box input {
  width: 100%;
  padding: 6px;
  margin-top: 0;
}

.question_tittle_box .submit_preference {
  border: none;
  padding: 10px 50px;
  color: #fff;
  background-color: #143e3c;
  text-align: center !important;
  margin-top: 25px !important;
  font-weight: 600;
}

.nav-link.active {
  color: #143e3c !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .free_flow_tab.active {
  width: 160%;
}

.question_tittle_box h5 {
  color: #343a40;
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px;
}

.question_tittle_box h2 {
  color: #6c757d;
  font-size: 15px;
  font-weight: bold;
  padding-top: 10px;
}

.add_row {
  float: left;
  width: 50%;
  margin-left: 15px;
  margin-right: 15px;
}

.add_row_multiple {
  width: 100%;
  float: left;
  margin-left: 15px;
  margin-right: 25px;
}

.add_buttons {
  clear: both;
  margin-top: 2rem;
}

.add_row_multiple input {
  width: 50%;
  float: left;
}

.add_row_multiple svg {
  float: left;
  margin-left: 1rem;
  margin-top: 10px;
}

.pl-15 {
  padding-left: 15px !important;
}

.do_you_link_right img {
  cursor: pointer;
}

.position_realtive {
  position: relative;
}

.pref_import_tittle span::after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 55px;
  right: unset;
  background-image: url("../images/right-arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 6px;
  top: 3px;
}

.pref_subtitle::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 165px;
  right: unset;
  background-image: url("../images/right-arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 6px;
  top: 3px;
}

.category_of_preferences_right .preference_cancel_btn {
  background-color: #e2e2e2;
  color: #8d8d8d;
  border-radius: 5px;
  padding: 6px 25px;
  margin-right: 35px;
  margin-bottom: 20px;
  margin-top: -10px;
}

.category_of_preferences_right .preference_save,
.category_of_preferences_right .preference_cancel_btn {
  display: none;
}

.accordion-body label:after {
  content: "";
  display: none;
  background-image: url("../images/cancel.png");
  width: 15px;
  height: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  left: 5px;
  top: 2px;
  right: 0px;
  position: relative;
}

.before .accordion-body label:after {
  display: inline-block;
}

.text_area {
  margin-top: 10px;
  resize: none;
  width: 80%;
  background: transparent;
  border: 1px solid #ececec;
  margin-bottom: 10px;
}

.text_area:focus-visible {
  outline: none;
  border: 1px solid #ececec;
  background: transparent;
}

.accordion-body {
  padding-right: 0;
}

.myyy .accordion-item {
  border: 1px solid #143e3c;
}

.accordion-item {
  margin-bottom: 12px;
  border: 1px solid transparent;
}

.accordion-button.collapsed {
  border: 0px;
}

.add-hide {
  display: none;
}

.add-show {
  display: block;
}

.tabs-number {
  display: inline-block;
  background-color: #e2efee;
  color: #143e3c;
  font-size: 15px;
  font-weight: 400;
  padding: 5px 8px 3px;
  border-radius: 6px;
  margin-left: 5px;
}

.show_save_popup .nav-tabs {
  display: flex;
  justify-content: space-between;
}

.show_save_popup .nav-tabs .nav-item {
  padding-right: 0;
}

.show_save_popup .nav-tabs .nav-item a {
  width: auto;
}

.category_content_left_box img {
  max-width: 45px;
}

.card-header {
  background-color: transparent;
}

.modal-header {
  border-bottom: 0;
}

.modal-header .close_modal_box {
  margin-bottom: 15px;
}

.modal-header .close_modal_box .close_icon {
  max-width: 16px;
  margin-right: 8px;
}

#preferenceListModal .card-header {
  border-bottom: 0;
}

.card-body h2 {
  font-size: 18px;
  font-weight: 700;
}

.display_grid {
  display: grid;
}

.preferences-company .category_content_right_box span {
  padding-left: 0;
}

.question_tittle_box .add_row,
.question_tittle_box .add_row_multiple {
  margin-left: 0;
}

.select-lg option:disabled {
  display: none;
}

.add-preference .select-lg {
  border: 1px solid #70707078;
  border-radius: 0;
}

@media screen and (max-width: 768px) {
  .delete_box {
    padding-top: 0;
  }
  .category_of_preferences_left {
    float: none;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .nav-tabs .nav-item {
    display: block;
    width: 100%;
    text-align: center;
  }

  .free_flow .outer_wrap {
    padding-bottom: 10px;
  }

  .Preference_edit_modal .poup_data {
    right: 0;
  }

  .container {
    padding: 0 15px;
  }
}

@media screen and (max-width: 480px) {
  .category_of_preferences_left {
    float: none;
    width: 100%;
  }

  .category_of_preferences_box {
    /* flex-direction: column; */
    gap: 10px;
    align-items: center;
  }

  .category_content_right_box {
    float: none;
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }

  .category_of_preferences_right .preference_save {
    padding: 6px 25px;
  }

  .category_of_preferences_right .preference_cancel_btn {
    padding: 6px 25px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
  }

  .modal-body {
    max-height: 500px;
    overflow-y: auto;
  }
}

.modal-body {
  max-height: 500px;
  overflow-y: auto;
}

.custom-dialog {
  width: 48% !important;
  top: 30%;
  position: relative;
}

.mc-option-header {
  clear: both;
  margin: 1rem auto;
}
.manage-btn {
  float: right;
    padding: 11px;
    border-radius: 4px;
    background-color: #143e3c;
    color: white;
    margin-top: 20px;
    border: none;
}