.store_container .quantity_container {
  display: flex !important;
  flex-direction: column !important;
  gap: 15px;
  padding: 10px 0px;
  align-items: flex-start;
}
.store_container .quantity_container .quantity_top_section {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  gap: 10px;
}
.store_container .quantity_container .qty_btn {
  padding: 0;
  width: auto;
  min-width: 30px !important;
  background-color: #fff;
  color: #000;
  border: none;
  border-color: transparent !important;
  font-size: 18px;
}
.backdrop_modal .MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.1);
}
.backdrop_modal .MuiBox-root {
  height: auto;
  max-height: 100%;
  width: 90%;
}

.backdrop_modal .MuiBox-root:focus-visible {
  border: none;
  outline: none;
}
.store_container .text-message .MuiAlert-icon {
  color: #04726d;
}
.backdrop_modal .close_btn {
  position: absolute;
  color: rgb(255, 255, 255);
  font-size: 13px;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #00000054;
  width: 23px;
  height: 23px;
  text-align: center;
  border-radius: 100%;
  font-weight: 700;
  padding: 3px;
}
@media (max-width: 600px) {
  .store_container .quantity_container .quantity_top_section {
    justify-content: center;
  }
  .store_container .quantity_container {
    align-items: center;
  }
}
