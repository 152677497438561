:root .filters-modal {
  scrollbar-color: #04726d #f5f5f5 !important;
  scrollbar-width: auto !important;
}
.customer_feedback_top .searchbar img {
  top: 36px;
  left: 35px;
}

.feedback_table .form-check {
  padding-top: 0;
}

.feedback_table .form-check input {
  height: 1rem;
}

.customer_feedback_top .searchbar {
  position: relative;
  display: inline-block;
}

.search-customer-feedback {
  max-width: 174px;
  width: 100%;
}

.search-customer-feedback .MuiInputBase-root {
  color: #6c757d;
  font-size: 14px;
}

.MuiOutlinedInput-input {
  height: auto !important;
}

.add_tag_modal {
  max-width: 350px;
}

.add_tag_modal .close_modal_box {
  right: 5px !important;
}

.add_tag_modal .modal-title label {
  color: #02736d;
}

.add_tag_modal .modal-header {
  border-bottom: 0 !important;
}

.add_tag_modal .modal-body {
  border-bottom: 0 !important;
  padding-right: 20px;
  padding-top: 30px;
}

.add_tag_modal .modal-footer {
  border-top: 0 !important;
}

.customer_feedback_top .add_compant_btn img {
  width: 100%;
  max-width: 16px;
  margin-right: 5px;
}

.add_tag_modal .cancel_button,
.add_tag_modal .save_button {
  margin-top: 0;
}

.customer_feedback_top .filter_btn {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 24px;
  border: 1px solid #676b6fe3;
  background: #fff;
  color: #6c757d;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  margin-left: 10px;
}

.customer_feedback_top .filter_btn img {
  width: 100%;
  max-width: 16px;
}

.filter_modal {
  width: 100%;
  max-width: 350px;
  top: 130px;
  right: 20px !important;
  left: auto !important;
  position: fixed;
}

.filter_modal .modal-header {
  border-bottom: 0 !important;
}

.filter_modal label {
  font-weight: 600;
  display: block;
  margin-bottom: 12px;
}

.filter_modal .modal-footer {
  justify-content: flex-start;
  border-top: 0 !important;
}

.reset_button {
  background-color: #e13773;
  color: #fff;
  border: 0;
  padding: 8px 20px;
}

.filter_modal .modal-header .close_modal_box .close_icon {
  right: 0;
}

#feedback tr td:nth-of-type(2) {
  min-width: 280px;
}

#feedback tr td:last-of-type {
  min-width: 100px;
}

.feedback_table {
  padding-top: 15px;
}

.feedbacktable_table img {
  padding-right: 0;
  margin-right: 15px;
}

.MuiDataGrid-columnSeparator {
  opacity: 0 !important;
}

.feedback-cell {
  white-space: break-spaces;
  height: 42px;
  text-overflow: initial !important;
}

.feedbacktable_table .MuiDataGrid-row {
  max-height: 82px !important;
  min-height: 82px !important;
}

.feedbacktable_table .MuiDataGrid-cell {
  min-height: 82px !important;
  max-height: 82px !important;
}

.feedback-name {
  display: flex;
  flex-direction: column;
}

.users-img.feedback {
  margin-top: -7px;
}

.feedback-name > span:nth-child(2) {
  font-size: 13px;
  color: #808080;
  padding-top: 5px;
}

.search-customer-feedback legend {
  width: 0;
}

.MuiDataGrid-cellContent.feedback-cell::first-letter,
.MuiDataGrid-cellContent.feedback-name > span::first-letter {
  text-transform: uppercase;
}

.feedbacktable_table {
  padding-top: 15px;
}

.filters-modal ::-webkit-scrollbar {
  -webkit-appearance: none !important;
  width: 5px !important;
}
.filters-modal ::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: #04726d !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}

.food_category_buttons .general {
  background-color: #e7e7e7;
  color: #a5a5a5;
}
