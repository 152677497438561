.profile_container .form_group {
  margin: 10px 0 !important;
}
.profile_container input {
  font-weight: 600;
  font-size: 14px;
  padding: 7px 10px;
  border: 1px solid #ced4da !important;
}
.profile_container .form_group label,
.profile_container textarea {
  margin-bottom: 10px;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #6c757d !important;
}
.profile_container .small_text {
  font-size: 14px !important;
  font-weight: 400 !important;
  text-transform: initial !important;
}
.profile_container #multiselect {
  border: 0px solid transparent !important;
  outline: none !important;
}
.profile_container .sub_Category {
  display: flex;
  gap: 5px;
}
.profile_container .text-message .MuiAlert-icon,
.profile_container .MuiAlert-message {
  color: #04726d;
  padding-top: 0;
  margin-right: 5px;
  align-self: center;
}
.profile_container .text-message .MuiAlert-icon {
  padding-top: 2px;
  align-self: start;
}
.profile_container .text-message .MuiAlert-icon svg {
  width: 16px;
  height: 16px;
}

.profile_container .MuiChip-deleteIcon {
  color: #fff !important;
  height: 17px;
  width: 17px;
}

.profile_container #companyType {
  padding: 0 10px;
  height: 38px !important;
  border: 1px solid #ced4da;
}

.profile_container .MuiSelect-select {
  padding: 8px 10px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #6c757d !important;
}
.profile_container .Photo_icon {
  width: 18px !important;
  height: 16px !important;
}
.profile_container .image_section img {
  max-height: 300px;
  width: auto;
  margin: 0 auto;
}
.profile_container .MuiAutocomplete-inputRoot {
  padding: 0px 10px !important;
  min-height: 39px !important;
  height: auto;
}
.profile_container .MuiAutocomplete-tag {
  height: 25px;
  padding: 0 !important;
  background-color: rgb(20, 62, 60);
  color: #fff !important;
}

.profile_container .react-tagsinput-input {
  height: auto;
  border: 0px !important; 
  width: auto;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-weight: 400;
}

.react-tagsinput {
  border:1px solid #ced4da !important;
  border-radius: 5px;
  font-weight: 400;
}

.react-tagsinput-tag {
  background-color: #04726d;
  color: #fff;
  border: 0;
  border-radius: 5px;
  font-weight: 400;
}

.react-tagsinput-remove {
  color: #fff;
  text-decoration: none;
}
.googlelocationnameprofile {
  padding:4px 10px;
  height: auto;
  min-height: 39px;
}
.googlelocationnameprofile:hover {
  border: solid 1px #111 !important;
}
.googlelocationnameprofile:focus {
  outline: #14579A;
  border: 2px solid #14579A !important;
}

.chip-grid .MuiChip-root {
  margin: 4px;
}

.chip-grid .MuiChip-root svg{
  fill: #888;
}