.header-wrapper {
  background-color: #fff;
  padding: 14px 0;
  box-shadow: 0px 0px 10px #00000014;
  position: fixed;
  width: 100%;
  z-index: 99;
}

.logo a {
  display: inline-block;
  width: 45px;
}

.menu-icon {
  margin-top: 9px;
  cursor: pointer;
}

.notification img {
  width: 100%;
  max-width: 25px;
}

.notification {
  padding-right: 15px;
}

.user_img {
  /* padding-right: 5px; */
  /* width: 100%; */
  width: 38px;
  background-color: #cbe2e1;
  border-radius: 100%;
  height: 38px;
}

.drop_arrow {
  padding: 0 0 3px 5px;
}

.user_tittle {
  color: #505d69;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none !important;
}

.header-left {
  width: 100%;
  max-width: 280px;
  justify-content: space-between;
  transition: max-width 0.5s;
}
.header-condese {
  width: 100%;
  max-width: 100px;
  justify-content: space-between;
  transition: max-width 0.5s;
}
.sidebar-active {
  width: 0px !important;
  transition: width 0.5s !important;
  display: none;
}

@media screen and (max-width: 768px) {
  .header-left.d-flex {
    width: 100%;
    max-width: 250px;
  }
}

@media screen and (max-width: 600px) {
  .header-left.d-flex {
    max-width: 200px;
  }
}

@media screen and (max-width: 480px) {
  .header-left.d-flex {
    width: auto;
  }

  .menu-icon {
    margin-left: 20px;
  }

  .user_tittle {
    font-size: 14px;
  }

  .notification {
    padding-right: 10px;
  }

  .notification img {
    max-width: 23px;
  }
}
