* {
  padding: 0;
  margin: 0;
  list-style: none;
}

@font-face {
  font-family: "nunito";
  src: url("../fonts/nunitosans-light-webfont.eot");
  src: url("../fonts/nunitosans-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/nunitosans-light-webfont.woff") format("woff"),
    url("../fonts/nunitosans-light-webfont.ttf") format("truetype"),
    url("../fonts/nunitosans-light-webfont.svg#nunito_sanslight") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "nunito";
  src: url("../fonts/nunitosans-regular-webfont.eot");
  src: url("../fonts/nunitosans-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/nunitosans-regular-webfont.woff") format("woff"),
    url("../fonts/nunitosans-regular-webfont.ttf") format("truetype"),
    url("../fonts/nunitosans-regular-webfont.svg#nunito_sansregular")
      format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "nunito";
  src: url("../fonts/nunitosans-semibold-webfont.eot");
  src: url("../fonts/nunitosans-semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/nunitosans-semibold-webfont.woff") format("woff"),
    url("../fonts/nunitosans-semibold-webfont.ttf") format("truetype"),
    url("../fonts/nunitosans-semibold-webfont.svg#nunito_sanssemibold")
      format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "nunito";
  src: url("../fonts/nunitosans-bold-webfont.eot");
  src: url("../fonts/nunitosans-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/nunitosans-bold-webfont.woff") format("woff"),
    url("../fonts/nunitosans-bold-webfont.ttf") format("truetype"),
    url("../fonts/nunitosans-bold-webfont.svg#nunito_sansbold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "nunito";
  src: url("../fonts/nunitosans-extrabold-webfont.eot");
  src: url("../fonts/nunitosans-extrabold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/nunitosans-extrabold-webfont.woff") format("woff"),
    url("../fonts/nunitosans-extrabold-webfont.ttf") format("truetype"),
    url("../fonts/nunitosans-extrabold-webfont.svg#nunito_sansextrabold")
      format("svg");
  font-weight: 800;
  font-style: normal;
}

body {
  font-family: "nunito", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 16px;
  color: #000;
  background-color: #f5f6f8;
  line-height: 1.4;
}

.container {
  max-width: 100%;
  padding: 0 20px;
}

.main-layout {
  background-color: #143e3c;
  padding-top: 70px;
}

.main {
  width: 100%;
  min-height: calc(-70px + 100vh);
  background-color: #f5f6f8;
  position: relative;
  z-index: 9;
}

.main > div.container {
  width: 100%;
  min-height: calc(-170px + 100vh);
  background-color: #f5f6f8;
}

.MuiDataGrid-root {
  border: 0 !important;
  background: #fff;
  border-radius: 0 !important;
}

.MuiCheckbox-root:before {
  content: "";
  -webkit-appearance: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: absolute !important;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  left: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #808080;
  border-radius: 5px;
}

.MuiCheckbox-root .MuiSvgIcon-root {
  opacity: 0;
}

.MuiCheckbox-root.Mui-checked:before {
  background-color: #04726d;
  border: 1px solid #04726d;
}

.MuiCheckbox-root.Mui-checked:after {
  content: "";
  display: block;
  position: absolute;
  top: 14px;
  left: 17px;
  width: 4px;
  height: 11px;
  border: solid #fff;
  border-width: 0px 2px 2px 0px;
  transform: rotate(45deg);
}

.MuiPaper-rounded .MuiCheckbox-root.Mui-checked:after {
  top: 13px !important;
}

.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
  border: none !important;
  outline: none !important;
}

.dashboard_tittle_box h3 {
  font-size: 20px;
  color: #000000;
  font-weight: 600;
  padding-top: 20px;
}

.dashboard_tittle_box h6 {
  font-size: 16px;
  color: #676b6fe3;
  font-weight: 600;
}

.dashboard_tittle_box span {
  padding-left: 25px;
}

.search-customer-feedback .MuiInputBase-input {
  margin: 0 !important;
  padding: 6px 20px 8px 40px;
  border-radius: 2px;
  font-size: 14px;
}

.search-customer-feedback .MuiInputBase-root {
  padding-left: 0 !important;
}

.search-customer-feedback .MuiInputAdornment-root {
  position: absolute;
  left: -10px;
}

.pr-3 {
  padding-right: 3rem;
}

.main_breadcrumbs_title {
  margin: 0;
  font-weight: 600;
  font-size: 1.25rem;
}

@media screen and (max-width: 1024px) {
  .main-layout {
    position: relative;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 15px;
  }
}

/* --------------------------- */

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50vh;
}

.dropdown-menu-item {
  display: flex;
  flex-direction: row;
}

.dropdown-menu-item .p-icon {
  cursor: pointer;
  flex: 20%;
  display: grid;
  place-items: center;
  margin-top: 2px;
}

.dropdown-menu-item span {
  cursor: pointer;
  flex: 60%;
}

.profile-dropdown a {
  text-decoration: none;
}
.profile-dropdown .dropdown-item {
  padding: 5px 3px;
}
.no-shadow {
  box-shadow: none !important;
}

.link-common,
.link-common:hover,
.link-common:active {
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.pointer {
  cursor: pointer;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear-both {
  clear: both;
}

.hidden {
  display: none;
}

.confirm-box__actions button {
  padding: 3px 3px 2px 2px;
}

.loading-wrapper-small {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.users-img {
  max-width: 32px;
  width: 100%;
  border-radius: 50%;
  display: inline-block;
  height: 32px;
}

.filters-modal .MuiBackdrop-root {
  opacity: 0 !important;
}

.filters-modal .MuiDialog-container .MuiPaper-root {
  position: absolute;
  right: 0;
  top: 150px;
  max-width: 335px;
  width: 100%;
  padding: 12px;
  max-height: calc(100vh - 185px);
  overflow-y: scroll;
}

.filters-modal .date-pickers-customers .MuiInputBase-input {
  height: 38px !important;
  font-size: 13px !important;
}

.filters-modal label {
  padding: 14px 0;
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.edit-data-form input {
  padding: 6px;
}

.tags-filter .MuiChip-filled {
  background: transparent;
  color: #000;
  font-weight: 600;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1qqsdnr-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding-right: 25px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: initial !important;
}

.filters-modal .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: initial !important;
}

.tags-cell {
  display: inline-block;
  background-color: #e3efee;
  border-radius: 5px;
  padding: 7px 10px;
  color: #04726d;
  font-weight: 600;
  user-select: none;
}

.tags-dialog .tags-cell {
  padding: 0;
  margin: 0 !important;
}
.tags-dialog .tags-cell span {
  padding: 7px 10px;
}

.add-tags-modal .MuiPaper-root {
  min-width: 300px;
  width: 100%;
  max-width: 380px;
  overflow-y: visible;
}

.add-tags-modal .MuiAutocomplete-listbox {
  max-height: 155px;
  overflow-y: scroll;
}

span.added-tag-remove:after {
  content: "x";
  margin-left: 5px;
  cursor: pointer;
  color: #832929;
}
.tags-cell .MuiListItemText-primary {
  user-select: none;
  cursor: pointer;
}

.tags-listing .MuiListItem-root .tags-cell .MuiTypography-root,
.tags-listing .MuiListItem-root .tags-cell {
  display: flex;
}
.apply-alert {
  background-color: #f5f6f8;
  color: #6e0f0f;
  float: right;
  font-size: 12px;
  padding: 2px 5px;
  user-select: none;
}

/* loader */
.main_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  /* min-width: calc(100% - 260px); */
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
}
.company_container .MuiAutocomplete-tag {
  background-color: rgb(20, 62, 60) !important;
  color: #fff !important;
  height: 25px;
  padding: 0 !important;
}
.company_container .MuiChip-deleteIcon {
  color: #fff !important;
  height: 17px;
  width: 17px;
}

.company_container #multiselect {
  border: 0px solid transparent !important;
  outline: none !important;
  width: 100% !important;
}
.company_container .MuiAutocomplete-inputRoot {
  padding: 5px 10px !important;
  min-height: 39px !important;
  height: auto;
}

.display_flex {
  display: flex !important;
}
