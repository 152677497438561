:root .filters-modal {
  scrollbar-color: #04726d #f5f5f5 !important;
  scrollbar-width: auto !important;
}
.w-12 {
  width: 12% !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.import_box span {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
}

.import_box p {
  color: #8f8f94;
  font-size: 20px;
  padding-top: 30px;
  margin-bottom: 10px;
}

.import_box a {
  text-decoration: none !important;
}

.import_box {
  background: #e3efee;
  padding: 70px;
  margin-top: 25px;
}

.submit_btn button {
  background-color: #143e3c;
  color: #fff;
  border: none;
  font-size: 18px;
  padding: 12px 60px;
  font-weight: 600;
  margin-top: 30px;
  border-radius: 5px;
}

.import_tittle {
  position: relative;
}

.import_tittle span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 5px;
  right: unset;
  background-image: url("../images/right-arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 6px;
  top: 3px;
}

.import_tittle span {
  display: inline-block;
  padding-left: 25px;
  position: relative;
}

.dashboard_tittle_box span {
  padding-left: 25px;
}

.company_table th {
  font-size: 16px !important;
  color: #676b6fe3 !important;
  font-weight: 600 !important;
  border: none !important;
  background-color: #fff;
}

.company_table {
  padding-top: 15px;
}

.company_table table.dataTable.no-footer {
  border: none !important;
}

.company_table tr {
  background-color: transparent !important;
}

.company_table td {
  background-color: #fff;
  margin-bottom: 15px;
  border-top: 1px solid #b3b3b3;
}

.company_table .spacer {
  min-height: 10px;
}

.company_table tr.spacer td {
  background-color: transparent;
}

.dashboard_tittle_box input {
  margin-top: 25px;
  padding: 8px 20px 10px 40px;
  border: 1px solid #676b6fe3;
  border-radius: 5px;
  font-size: 16px;
}

.form-control:focus {
  /* border: none; */
  box-shadow: none;
}

.dashboard_tittle_box input:focus {
  border: 1px solid #676b6fe3;
}

.company_serch_btn {
  margin-top: 25px;
  background: #097b75;
  font-size: 18px;
  color: #fff;
  border: none;
  padding: 11px 40px;
  border-radius: 7px;
  font-weight: 600;
}

.add_compant_btn button {
  background: #097b75;
  color: #fff;
  font-size: 16px;
  border: none;
  padding: 10px 15px;
  font-weight: 500;
  margin-top: 25px;
}

.company_import_btn {
  background-color: #e33773;
  color: #fff;
  font-size: 16px;
  border: none;
  padding: 10px 30px 10px 20px;
  font-weight: 500;
  margin-top: 25px;
}

.right_align {
  text-align: right;
}

.pending_btn {
  background: #fbe8ef;
  padding: 5px 20px 6px 13px;
  border-radius: 50px;
  color: #e13773;
  text-decoration: none;
}

.active_btn {
  background: #e3efee;
  padding: 5px 38px 6px 13px;
  border-radius: 50px;
  color: #04726d;
  text-decoration: none;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 15px 18px !important;
  color: #6c757d;
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 15px 18px !important;
}

.company_table img {
  padding-right: 0;
  margin-right: 15px;
}

.form-check .form-check-input:hover {
  cursor: pointer;
}

.searchbar {
  position: relative;
}

.searchbar img {
  position: absolute;
  top: 12px;
  left: 9px;
}

.company_import_btn img {
  padding-right: 5px;
}

.copywrite h1 {
  color: #98a6ad;
  font-size: 15px;
  text-align: center;
  margin: 30px 0px;
  font-weight: 600;
}

.dashboard_tittle_box .date_input {
  color: #6c757d !important;
}

a.filter_btn {
  display: inline-block;
  padding: 6px 20px !important;
  margin-top: 24px;
  border: 1px solid #676b6fe3 !important;
  background: #fff !important;
  color: #6c757d !important;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  border-radius: 2px !important;
}

a.filter_btn img {
  margin-right: 6px;
}

.date_box {
  position: relative;
}

.date_box img {
  position: absolute;
  left: 25px;
  top: 38px;
}

.company_table .form-check-input:checked {
  background-color: #04726d;
  border-color: #04726d;
}

.company_table .form-check-input:focus {
  box-shadow: none;
}

.center_img {
  margin: 0 auto;
  display: table;
}

.drop_btn {
  background: #fbe8ef;
  border: none;
  color: #e13773;
  border-radius: 50px;
  padding: 5px 20px;
}

.company_table .btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle,
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  background: #fbe8ef;
  color: #e13773;
  border: transparent;
  box-shadow: none;
}

.drop_btn:hover {
  background: #fbe8ef;
  border: none;
  color: #e13773;
}

.drop_active_btn {
  background: #e3efee;
  border: none;
  color: #04726d;
  border-radius: 50px;
  padding: 5px 30px;
}

.company_table .btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle,
.btn-check:focus + .btn-secondary,
.drop_active_btn:focus {
  background: #e3efee;
  color: #04726d;
  border: transparent;
  box-shadow: none;
}

.drop_active_btn:hover {
  background: #e3efee;
  border: none;
  color: #04726d;
}

.to_tittle {
  font-size: 20px !important;
  color: #6c757d;
}

.dropdown-menu {
  min-width: 11rem;
}

.dropdown .dropdown-item {
  color: #6c757d;
}

.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.show_popup {
  display: none;
  background: transparent;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
}

.poup_data {
  background: #fff;
  position: absolute;
  top: 168px;
  right: 20px;
  width: 100%;
  max-width: 420px;
  border: 2px solid #707070bd;
  padding: 20px;
  border-radius: 5px;
  z-index: 9;
  text-align: left;
}

.input_modal {
  display: flex;
}

.left_box,
.right_box {
  width: 48%;
  float: left;
  position: relative;
}

.left_box img,
.right_box img {
  position: absolute;
  left: 12px;
  top: 76px;
}

.right_box {
  margin-left: 4%;
}

.left_box .modal-textbox,
.right_box .modal-textbox {
  margin-top: 0;
  width: 100%;
}

.poup_data h1 {
  color: #02736d;
  font-size: 25px;
  font-weight: 500;
  padding-bottom: 6px;
  position: relative;
}

.poup_data p {
  color: #1b1b1b;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}

.input_modal h3 {
  color: #8d8d8d;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 12px;
}

.input_modal input {
  color: #8d8d8d;
  font-size: 16px;
  font-weight: 500;
  padding-left: 35px;
}

.rest_fliter {
  border: none;
  padding: 10px 25px;
  color: #fff;
  margin-top: 15px;
  background-color: #e13773;
}

.poup_data .close_icon {
  position: absolute;
  top: 25px;
  right: 20px;
}

.customer_action_dropdown ul {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate(-56px, 0px) !important;
}

.close_icon:hover {
  cursor: pointer;
}

.customer_action_dropdown img {
  cursor: pointer;
}

.dropdown-toggle::after {
  color: #000;
}

/* Custom scripts */

.inset-hide-1 {
  inset: auto !important;
}

table {
  width: 100% !important;
}

.tag {
  background-color: #e3efee;
  font-size: 14px;
  padding: 10px 15px;
  color: #04726d;
  border-radius: 5px;
}

/* Change sorting icon position in datatable */
/*
table.dataTable thead .sorting_asc {
  background: url("http://cdn.datatables.net/1.10.0/images/sort_asc.png")
    no-repeat center left;
  background-color: #ffffff;
}
table.dataTable thead .sorting_desc {
  background: url("http://cdn.datatables.net/1.10.0/images/sort_desc.png")
    no-repeat center left;
  background-color: #ffffff;
}
table.dataTable thead .sorting {
  background: url("http://cdn.datatables.net/1.10.0/images/sort_both.png")
    no-repeat center left;
  background-color: #ffffff;
}

*/
.confirm-box {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
}

.customers-header .MuiInputBase-root {
  height: 38px;
  color: #6c757d;
  font-size: 14px;
}

.customers-header .search-customer {
  max-width: 200px;
  width: 100%;
}

.customers-header h3 {
  font-size: 20px;
  font-weight: 600;
}

.breadcrumb-btn {
  min-width: auto !important;
  padding-left: 0 !important;
  font-size: 15px !important;
  font-family: "nunito", Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
  color: #000000 !important;
  opacity: 0.6 !important;
  background: transparent !important;
  font-weight: 600 !important;
}

.breadcrumb-btn .MuiTouchRipple-root {
  display: none;
}

.MuiBreadcrumbs-separator {
  font-size: 25px;
  opacity: 0.5;
  padding: 7px 5px 7px;
}

.date-pickers-customers .MuiInputBase-root {
  max-width: 150px;
  width: 100%;
  padding-right: 0;
  position: relative;
}

.date-pickers-customers .MuiInputAdornment-root {
  position: absolute;
  left: 0;
  right: 0;
}

.date-pickers-customers .MuiInputAdornment-root .MuiButtonBase-root {
  width: 100%;
  position: relative;
  background: none !important;
}

.date-pickers-customers .MuiInputAdornment-root .MuiButtonBase-root span {
  background-color: transparent !important;
}

.date-pickers-customers .MuiInputAdornment-root .MuiButtonBase-root:focus {
  background: none !important;
}

.date-pickers-customers .MuiInputAdornment-root .MuiButtonBase-root svg {
  position: absolute;
  left: 10px;
  max-width: 14px;
}

.date-pickers-customers .MuiInputBase-input {
  padding: 0;
  text-align: right;
  padding-right: 14px;
}

.custom-mui-button {
  background: #097b75 !important;
  color: #fff !important;
  font-size: 16px !important;
  border: none !important;
  padding: 4px 16px !important;
  font-weight: 500 !important;
  margin-top: 0px !important;
  border-radius: 2px !important;
  text-transform: none !important;
  box-shadow: none !important;
  white-space: nowrap !important;
}

.image_data {
  display: grid;
  place-items: center;
  min-height: 90px;
  margin-top: 20px;
}

.add-tags-btn {
  padding: 7px 20px !important;
}

.no-tags {
  display: inline-block;
  background-color: #e3efee;
  border-radius: 5px;
  padding: 7px 10px;
  color: #680a2e;
  font-weight: 600;
}

.custom-mui-button.tags-button {
  color: #6c757d !important;
  background: transparent !important;
  padding: 5px !important;
  min-width: 10px;
}

.tags-dialog .MuiPaper-root.MuiPaper-elevation {
  max-width: 650px;
  width: 100%;
}

.add-tags-modal .MuiTypography-h6 {
  color: #02736d;
  font-weight: 600;
  font-size: 18px;
}

.add-data-form input {
  padding: 15px 6px;
}

.tags-filter-section {
  display: flex;
  gap: 5px;
  flex-flow: row wrap;
}

.tags-cell.filter {
  min-width: fit-content;
  margin-bottom: 2px;
  cursor: pointer;
  padding: 2px 5px;
}

.tags-cell.filter.listing {
  margin-right: 5px;
  cursor: initial;
  user-select: none;
}

.tags-cell.filter.listing span {
  cursor: pointer;
}

.tags-filter-label {
  font-size: 18px;
  font-weight: 600;
  color: #747474;
}

.MuiAutocomplete-noOptions {
  display: none !important;
}

.filters-modal ::-webkit-scrollbar {
  -webkit-appearance: none !important;
  width: 5px !important;
}
.filters-modal ::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: #04726d !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}

.perks-listing {
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.perks-listing-outer {
  display: flex;
  align-items: center;
}

.cutomer-note-text {
  font-size: 15px !important;
  position: absolute;
  top: -19px;
  left: -9%;
  text-wrap: nowrap;
}

/* Media */
@media (max-width: 1480px) {
  .breadcrumb-btn {
    font-size: 13px !important;
    padding: 0 !important;
  }
}

@media (max-width: 1320px) {
  .date-pickers-customers .MuiInputAdornment-root .MuiButtonBase-root svg {
    left: 0;
  }
  .cutomer-note-text {
    left: -3%;
  }
}

@media (max-width: 900px) {
  .cutomer-note-text {
    position: static;
    text-wrap: wrap;
  }
}
