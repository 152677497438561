.cutomer-modal-box .appBar {
    box-shadow: none;
}

.preference-main-div {
    display: flex;
    flex-direction: column;
}

.Radio-btns label span {
  font-size: 14px;
}
 
.box-chips {
    background-color: #E1EFEE;
    width: auto;
    text-align: center;
    border-radius: 30px;
    color: #04726d;
    font-size: 14px !important;
    cursor: pointer;
    padding: 5px 20px;
    vertical-align: middle;
}

.box-chips span {
    border-radius: 50%;
    padding: 2px;
    background-color: #04726d;
    color: white;
    font-size: 12px;
    text-align: center;
    width: 23px;
    height: 23px;
    display: inline-block;
    line-height: 20px;
    margin-left: 4px;
    
}

.cutomer-modal-box  {
    width: 100% !important;
}

.customer-modal-divider {
    border-width: 2px;
    border-color: rgba(0, 0, 0, 0.2);
}

.cutomer-modal-box .MuiButtonBase-root {
    color: black;
    font-size: 15px;
    text-transform: capitalize;
    border-bottom: solid 2px transparent;
}

.cutomer-modal-box .MuiButtonBase-root.Mui-selected {
    background-color: #E0EEEE;
    color: #2F5A58;
    border-bottom: solid 2px #00736D;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.cutomer-modal-box .MuiTabs-indicator {
    background-color: transparent;
}

.cutomer-modal-box .MuiTabs-fixed {
    border-bottom: solid 2px rgba(0, 0, 0, 0.1);
}

.custom-modal-outer > .MuiBox-root {
    padding: 0;
}

.sub-tabs .MuiButtonBase-root {
    text-transform: capitalize;
    padding-left: 0;
    padding-right: 0;
    color: black;
}

.sub-tabs .MuiButtonBase-root.Mui-selected {
    color: #2F5A58;
}

.sub-tabs .MuiTabs-indicator {
    background-color:#00736D;
}

.sub-tabs .Mui-disabled {
    color: #B1B1B1;
    background: none;
}

.customer-modal > .MuiPaper-root {
    width: 100%;
    max-width: 600px;
}

.list-item {
    padding: 0px !important;
}

.bio-main-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0px !important;
}

.customer-modal-icons .MuiSvgIcon-root {
    background-color: white;
    padding: 3px 4px;
    border-radius: 4px;
}

.customer-modal-tabpanel > .MuiBox-root {
    padding: 0px;
}

.Preferences-TabPanel {
    width: 49%;
    margin-bottom: 15px;
}

.Preferences-TabPanel:empty {
    display: none;
}