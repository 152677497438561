
.referral_tabs .MuiTab-root {
    text-transform: capitalize;
}

.main_box .css-1gcrpeo-MuiButtonBase-root-MuiTab-root.Mui-selected {
   color: #143e3c;
   border-bottom: solid 2px #143e3c;
}

