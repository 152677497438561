.locations-wrapper .coming-soon {
  display: inline-block;
  background-color: #e3efee;
  color: #04726d;
  padding: 5px;
  font-size: 16px;
  border-radius: 3px;
}

.locations-wrapper .text-message {
  color: #000;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
}
.locations-wrapper .text-message .MuiAlert-icon {
  color: #04726d;
}

.locations-wrapper form input {
  border: 0 !important;
}

.locations-wrapper iframe {
  margin-bottom: 20px;
}

.locations-wrapper .MuiFormLabel-root {
  font-size: 14px;
  margin-bottom: 10px;
}

.locations-wrapper .cancel-btn {
  background-color: #e33773 !important;
}
