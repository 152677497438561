.invite_link_input input {
  padding: 2px 15px;
  height: 36px !important;
}
.invite_link_btn {
  height: 36px !important;
  font-size: 13px !important;
  padding: 8px 16px !important;
  cursor: pointer;
}
.social_icon {
  width: 48px !important;
  height: 48px !important;
}
.invite_container {
  background-color: rgb(229, 246, 253);
  padding: 30px;
  margin-block: 20px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .invite_container {
    padding: 10px;
  }
}

.mui-label {
  margin-left: 0 !important;
}

