.sidebar {
  height: 100%;
  width: 0;
  z-index: 1;
  top: 72px;
  left: 0;
  background-color: transparent;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 22px;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

.navigation-title {
  color: #fff;
  font-size: 14px;
  padding-top: 15px;
  padding-left: 15px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.sidebar ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar ul li {
  display: block;
}

.sidebar ul li a:hover,
.sidebar ul li a.active {
  color: #fff;
  background-color: #113533;
}

#mySidebar {
  width: 0px;
}

.toggle #mySidebar {
  width: 260px;
  flex-shrink: 0;
}

.toggle .sidebar-fixed {
  position: fixed;
  width: 100%;
  top: 71px;
  left: 0;
  max-width: 260px;
  overflow: auto;
  height: calc(100vh - 70px);
  padding-top: 20px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.sidebar-fixed::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* ------------------------ new added -------------- */

.sidebar ul li a {
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  text-decoration: none;
  padding: 13px 15px;
}

.sidebar ul li a .icon {
  cursor: pointer;
  flex: 35px;
  display: grid;
  place-items: center;
}
.sidebar ul li a .title {
  cursor: pointer;
  flex: calc(100% - 40px);
  padding-left: 0;
}
@media screen and (max-width: 1024px) {
  /* .toggle #mySidebar {
    width: 0;
  }
 .toggle .sidebar-fixed {
    width: 0;
  } */
  #mySidebar {
    width: 100%;
    max-width: 260px;
    position: fixed;
    top: 70px;
    left: 0;
    bottom: 0;
    height: 100%;
    background-color: #143e3c;
    z-index: 22;
    transition: max-width 0.3s;
  }
}

@media screen and (max-width: 768px) {
  #main {
    padding: 10px 0;
  }
}
