.w-12 {
  width: 12% !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pb-4 {
  padding-bottom: 4px !important;
}
.import_box span {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}
.import_box p {
  color: #8f8f94;
  font-size: 20px;
  padding-top: 30px;
  margin-bottom: 10px;
}
.import_box a {
  text-decoration: none !important;
}
.import_box {
  background: #e3efee;
  padding: 70px;
  margin-top: 25px;
}
.submit_btn button {
  background-color: #143e3c;
  color: #fff;
  border: none;
  font-size: 18px;
  padding: 12px 60px;
  font-weight: 600;
  margin-top: 30px;
  border-radius: 5px;
}
.import_tittle {
  position: relative;
}
.import_tittle span::after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 50px;
  background-image: url("../images/right-arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 6px;
  top: 4px;
}

.dashboard_tittle_box span {
  padding-left: 25px;
}
.company_table th {
  font-size: 16px !important;
  color: #676b6fe3 !important;
  font-weight: 600 !important;
  border: none !important;
  background-color: #fff;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.company_table {
  padding-top: 15px;
}
.company_table table.dataTable.no-footer {
  border: none !important;
}
.company_table tr {
  background-color: transparent !important;
}
.company_table td {
  background-color: #fff;
  margin-bottom: 15px;
  border-top: 10px solid #f5f6f8;
}
.company_table .spacer {
  min-height: 10px;
}
.company_table tr.spacer td {
  background-color: transparent;
}
.dashboard_tittle_box input {
  margin-top: 25px;
  padding: 8px 20px 10px 40px;
  border: 1px solid #676b6fe3;
  border-radius: 5px;
  font-size: 20px;
}
.form-control:focus {
  /* border: none; */
  box-shadow: none;
}
.dashboard_tittle_box input:focus {
  border: 1px solid #676b6fe3;
}
.company_serch_btn {
  margin-top: 25px;
  background: #097b75;
  font-size: 18px;
  color: #fff;
  border: none;
  padding: 11px 40px;
  border-radius: 7px;
  font-weight: 600;
}
.add_compant_btn {
  background: #097b75;
  color: #fff;
  font-size: 16px;
  border: none;
  padding: 10px 15px;
  font-weight: 500;
  margin-top: 25px;
}
.company_import_btn {
  background-color: #e33773;
  color: #fff;
  font-size: 16px;
  border: none;
  padding: 10px 30px 10px 20px;
  font-weight: 500;
  margin-top: 25px;
}
.company_btn_container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: right;
}
.right_align {
  text-align: right;
}
.pending_btn {
  background: #fbe8ef;
  padding: 5px 20px 6px 13px;
  border-radius: 50px;
  color: #e13773;
  text-decoration: none;
}
.active_btn {
  background: #e3efee;
  padding: 5px 38px 6px 13px;
  border-radius: 50px;
  color: #04726d;
  text-decoration: none;
}
table.dataTable tbody th,
table.dataTable tbody td {
  padding: 8px 18px !important;
  color: #6c757d;
}
/* .company_table img {
  padding-right: 10px;
} */
.serchbar {
  position: relative;
}
.serchbar img {
  position: absolute;
  top: 16px;
  left: 9px;
}
.company_import_btn img {
  padding-right: 5px;
}
.copywrite h1 {
  color: #98a6ad;
  font-size: 15px;
  text-align: center;
  margin: 30px 0px;
  font-weight: 600;
}
.drop_btn {
  background: #fbe8ef;
  border: none;
  color: #e13773;
  border-radius: 50px;
  padding: 5px 20px;
}
.company_table .btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle,
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  background: #fbe8ef;
  border: none;
  color: #e13773;
  box-shadow: none;
}
.drop_btn:hover {
  background: #fbe8ef;
  border: none;
  color: #e13773;
}
.drop_active_btn {
  background: #e3efee;
  border: none;
  color: #04726d;
  border-radius: 50px;
  padding: 5px 30px;
}
.company_table .btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle,
.btn-check:focus + .btn-secondary,
.drop_active_btn:focus {
  background: #e3efee;
  border: none;
  color: #04726d;
  box-shadow: none;
}
.company_table .btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle,
.btn-check:focus + .btn-secondary,
.drop_active_btn:active {
  background: #fbe8ef !important;
  border: none;
  color: #e13773 !important;
  box-shadow: none;
}
.drop_active_btn:hover {
  background: #e3efee;
  border: none;
  color: #04726d;
}
.to_tittle {
  font-size: 20px !important;
  color: #6c757d;
}
.dropdown-menu {
  min-width: 7rem;
}
.dropdown .dropdown-item {
  color: #6c757d;
}
.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
.action_drop ul {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(-99px, 17px) !important;
}
.action_drop img {
  cursor: pointer;
}
.dropdown-toggle::after {
  color: #000;
}
@media screen and (max-width: 1200px) {
  #companyListing_wrapper {
    overflow-x: auto;
  }
  #companyListing_wrapper table.dataTable tbody th,
  #companyListing_wrapper table.dataTable tbody td {
    font-size: 14px;
    padding: 8px 10px !important;
  }
  .company_table th {
    font-size: 14px !important;
  }
  #companyListing_wrapper .btn {
    font-size: 14px;
    padding: 5px 18px;
  }
  .action_drop ul {
    right: 0;
    transform: none !important;
  }
}

@media screen and (max-width: 600px) {
  .add_compant_btn {
    padding: 10px;
  }
  .company_import_btn {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* Custom scripts */

.inset-hide {
  inset: 0 !important;
}

.dropdown-menu li:hover {
  cursor: pointer;
}

.listStyle:nth-child(even) {
  color: #262727;
}

li:nth-child(odd) {
  color: #262727; /*#8b8b8b */
}

/* Change sorting icon position in datatable */

.is_google_place {
  padding: 5px;
  font-size: 8px;
  background: #e3efee;
  border: none;
  color: #04726d;
  border-radius: 25px;
  font-weight: bold;
}

.drop_claim_btn {
  background: #097b75 !important;
  border: none;
  color: #e3efee !important;
  border-radius: 50px;
  padding: 5px 30px;
}

.drop_claim_btn:hover {
  background: #e3efee !important;
  border: none;
  color: #097b75 !important;
}

.drop_claim_btn:active {
  background: #e3efee !important;
  border: none;
  color: #097b75 !important;
}

.drop_claim_btn:focus {
  background: #e3efee !important;
  border: none;
  color: #097b75 !important;
}

.drop_disable_btn {
  background: #696b6b !important;
  border: none;
  color: #e3efee !important;
  border-radius: 50px;
  padding: 5px 30px;
}

.drop_disable_btn:hover {
  background: #e3efee !important;
  border: none;
  color: #696b6b !important;
}

.drop_disable_btn:active {
  background: #e3efee !important;
  border: none;
  color: #696b6b !important;
}

.drop_disable_btn:focus {
  background: #e3efee !important;
  border: none;
  color: #696b6b !important;
}

.confirm-box {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
}
