.perks_container .form_group {
  margin: 10px 0 !important;
}
.perks_container input,
.perks_container textarea,
.perks_container .MuiSelect-select {
  font-weight: 600;
  font-size: 14px;
  padding: 6px 10px;
  /* height: 36px !important; */
  border: 1px solid #ced4da !important;
  color: #000 !important;
}
.perks_container .perk_input input {
  border: none !important;
  padding-left: 0 !important;
}
.perks_container label {
  margin-bottom: 10px;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #6c757d !important;
}
.perks_container .input_file {
  border: 1px dashed #ced4da !important;
  padding: 16px !important;
}
.perks_container .input_file svg {
  width: 21px;
  height: 21px;
}
.perks_container .perks_top_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.perks_container .paragraph {
  font-size: 13px;
  color: #808080;
  margin-block: 10px !important;
}
.perks_container ul.perks_benefits_list {
  margin-inline: 20px;
  display: list-item;
  margin-block: 15px !important;
}
.perks_container ul.perks_benefits_list li {
  font-size: 13px;
  color: #808080;
  list-style-type: disc;
}
.perks_container textarea {
  color: #000;
  opacity: 0.6;
}
.perks_container .benefits_list {
  display: flex;
  margin: 5px 15px;
}
.perks_container ul {
  margin: 0;
  padding: 0;
}
.perks_container .perk_upload_image {
  max-height: 200px;
  max-width: 200px;
  object-fit: cover;
}
.perks_container .hotel_image {
  max-width: 260px;
  object-fit: cover;
  width: 100%;
  max-height: 260px;
  border-radius: 5px;
  height: 100%;
  min-height: 260px;
}
.perks_container .upload_icon_button:hover {
  background-color: transparent !important;
}
.perks_container .perk_bottom_section {
  color: #808080;
}
.perks_container .perk_date_section {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-block: 5px 8px;
}
.perks_container .save_btn {
  background-color: #097b75 !important;
}
.perks_container .save_btn:hover {
  background-color: #04736d !important;
}
.perks_container .cancel_btn {
  background-color: #e33572 !important;
}
.perks_container .cancel_btn:hover {
  background-color: #c32c5f !important;
}
.perks_container .image_container {
  position: relative;
  top: 0;
  left: 0;
}
.perks_container .image_btn {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  max-width: 183px;
  margin-bottom: 20px;
  margin-left: 34.2px;
  padding: 5px 5px !important;
  font-size: 13px !important;
}
.perk_modal_container {
  width: 520px !important;
  border-radius: 2px !important;
  border: 0px solid transparent !important;
  box-shadow: none !important;
}
.perk_modal_container ul.perks_list {
  max-width: 100% !important;
}
.perk_modal_container ul.perks_list li {
  padding-inline: 0 !important;
  padding-block: 15px !important;
}
.perk_modal_container ul.perks_list li .MuiListItemText-primary {
  padding-bottom: 7px !important;
}
.perks_container .MuiInputBase-multiline {
  min-height: 37px;
  height: 100%;
  padding: 0 !important;
}
.perks_container .text-message {
  color: #000;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
}
.perks_container .text-message .MuiAlert-icon {
  color: #04726d;
}

.perks_container .top_Section {
  margin-bottom: -25px !important;
}
@media (max-width: 500px) {
  .perks_container .perks_top_section {
    flex-direction: column;
    align-items: start;
  }
}
