.billing_container .billing_card {
  padding: 15px !important;
  border: 0px !important;
}
.billing_container .billing_card.recommended {
  padding: 15px !important;
  border: 0px !important;
  background-color: #04726d !important;
  color: #fff !important;
}
.billing_gray_text.recommended {
  color: #fff !important;
}
.billing_gray_text {
  color: "#808080" !important;
}
.billing_container .card_content {
  padding: 0;
}
.billing_container .card_content .upgrade-btn{
  color: #0a0a0a;
  background-color: #04726d;
 
}
.billing_container .billing_btn {
  width: 100%;
  border-radius: 2px;
  background-color: #04726d !important;
  font-size: 16px;
  color: #fff !important;
  margin-block: 20px;
  padding: "2px 10px";
}

.billing_container .billing_btn.recommended {
  width: 100%;
  border-radius: 2px;
  background-color: #fff !important;
  font-size: 16px;
  color: #04726d !important;
  margin-block: 20px;
  padding: "2px 10px";
  border: 1px solid #808080 !important;
}
.billing_container .recommended_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.billing_container .billing_btn:hover {
  background-color: #04726d;
  color: #fff;
}
.billing_container .billing_points {
  display: flex;
  gap: 10px;
  padding-block: 5px;
  color: #0a0a0a;
}
.billing_container .billing_points.recommended {
  color: #fff;
}
.billing_container .billing_top_section {
  display: flex;
  justify-content: space-between;
  margin-block: 15px;
}
.billing_container .billing_top_section .time_warning {
  color: #04726d;
  font-size: 1rem;
  background-color: #e3efee;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 15px;
  border-radius: 3px;
  font-style: italic;
}
.billing_container .billing_points svg {
  font-size: 20px;
  color: #04726d;
}
.billing_container .billing_points.recommended svg {
  font-size: 20px;
  color: #fff !important;
}
.time_warning .time_image {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.billing_container .card_content ul {
  padding: 0;
}
.footer-text {
  font-size: 18px;
}
.billing_container .billing_btn.recommended.Mui-disabled {
  background-color: #808080 !important;
  opacity: 0.7;
  color: #fff !important;
}
@media (max-width: 550px) {
  .warning_section {
    flex-direction: column;
  }
  .billing_container .billing_top_section .time_warning {
    align-items: start;
    justify-content: start;
    padding: 5px 15px;
  }
}
