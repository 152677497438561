.connection_container .cancel_btn {
  background-color: #097b75 !important;
  box-shadow: none;
}
.connection_container .cancel_btn:hover {
  background-color: #04736d !important;
  box-shadow: none;
}
.connection_container .key_btn {
  background-color: #e33572 !important;
  box-shadow: none;
}
.connection_container .text-message {
  color: #000;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
}
.connection_container .text-message .MuiAlert-icon {
  color: #04726d;
}
.connection_container .key_btn:hover {
  background-color: #c32c5f !important;
  box-shadow: none;
}
.api_input input {
  padding: 2px 11px;
  height: 35px !important;
}
