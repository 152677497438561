.edit_from .form-group {
  padding-bottom: 15px;
}
.edit_from {
  padding-top: 15px;
}
.edit_from label {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 6px;
}
.edit_from input,
.edit_from select {
  padding: 12px 15px;
  border-radius: 6px;
  border: 1px solid #707070;
}
.form-select:focus {
  border: none;
  box-shadow: none;
}
.form-control:focus {
  border-color: #707070 !important;
  box-shadow: none;
}
.edit_from select {
  font-weight: 600;
}
.edit_from select:focus {
  border-color: #707070 !important;
}

.serch_btn {
  background-color: #e13773;
  border: none;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 7px;
  margin-top: 34px;
  text-align: center;
}
.type_btn {
  background-color: #143e3c;
  border: none;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  padding: 10px 50px;
  border-radius: 7px;
  margin-top: 34px;
  text-align: center;
}
.w-12 {
  width: 12% !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pb-4 {
  padding-bottom: 4px !important;
}
.import_tittle span:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 5px;
  right: unset;
  background-image: url("../images/right-arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 6px;
  top: 3px;
}

.import_tittle span {
  display: inline-block;
  padding-left: 25px;
  position: relative;
}

.dashboard_tittle_box h6 {
  position: relative;
}
.position_relative {
  position: relative;
}
.google_serch img {
  position: absolute;
  top: 48px;
  left: 31px;
}
.google_serch input {
  padding-left: 50px;
}
.save_button {
  background-color: #143e3c;
  color: #fff;
  border: none;
  margin-right: 10px;
  font-size: 18px;
  padding: 10px 45px;
  border-radius: 5px;
  margin-top: 30px;
  font-weight: 500;
}
.cancel_button {
  background-color: #e2e2e2;
  color: #8d8d8d;
  border: none;
  font-size: 18px;
  padding: 10px 45px;
  border-radius: 5px;
  margin-top: 30px;
  font-weight: 500;
}
.text-right {
  text-align: right !important;
}
.company_detil_wrap h3 {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}
.company_detil_wrap span {
  font-size: 20px;
  color: #04726d;
  font-weight: 500;
  text-align: right;
  display: block;
}
.company_detil_wrap {
  padding: 10px 0px 5px;
}
.detail_update {
  background-color: #143e3c;
  color: #fff;
  font-size: 18px;
  padding: 10px 40px;
  border: none;
  margin-top: 25px;
  font-weight: 600;
  border-radius: 6px;
}
.details_box {
  background-color: #fff;
  padding: 10px 20px;
  margin-top: 15px;
  padding-bottom: 30px;
}
.align_right {
  text-align: right;
}
.details_box input {
  font-weight: 600;
  font-size: 17px;
}
.form-check label {
  font-size: 18px;
  color: #707070;
  font-weight: 600;
  padding-top: 5px;
}
.form-check {
  padding-top: 42px;
}
.form-check input {
  margin-right: 20px;
  height: 30px;
}
.form-check-input:checked {
  background-color: #04736d;
  border-color: #04736d;
}
.form-check-input:focus {
  box-shadow: none;
}
.pt-10 {
  padding-top: 10px !important;
}
.telphone {
  padding-left: 100px !important;
}

.flag_img {
  position: absolute;
  top: 47px;
  left: 15px;
}
.drop_img {
  position: absolute;
  top: 55px;
  left: 65px;
}
.pl-60 {
  padding-left: 60px !important;
}
.uplod_img {
  position: absolute;
  top: 49px;
  left: 15px;
}
.copywrite h1 {
  color: #98a6ad;
  font-size: 15px;
  text-align: center;
  margin: 30px 0px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .form-check {
    padding-top: 0;
  }
}

@media screen and (max-width: 480px) {
  .pr-3 {
    padding-right: 1.5rem;
  }
  .form-check input {
    margin-right: 15px;
  }
}

/* Custom scrolling */
.Scroll {
  min-height: 650px;
  overflow-y: scroll;
}

.frmErr {
  color: red;
}

.mainCloseDrop {
  position: relative;
}
.closeDrop {
  position: absolute;
  top: 42px;
  right: 10px;
}
.closeDrop button {
  background-color: transparent;
  /* border: 1px solid #a8a8a8; */
  padding: 0 5px;
  border-radius: 100%;
}
.companyLabel {
  padding-block: 10px;
}
/* Custom selection of location searching */

.cmpy-location-srch {
  display: flex;
  position: relative;
}

.autocomplete-dropdown-container {
  background-color: #fff;
  box-shadow: 3px 10px 10px #00000029;
  left: 15px;
  max-width: 480px;
  position: absolute;
  text-align: left;
  top: 100px;
  width: 100%;
  z-index: 999;
}

.autocomplete-dropdown-container ul {
  height: 100%;
  list-style: none;
  margin: 0;
  max-height: 220px;
  overflow-y: scroll;
  padding: 0;
}

.autocomplete-dropdown-container ul li {
  display: block;
  margin: 5px 0;
  padding: 5px 20px;
  width: 100%;
  cursor: pointer;
}

.autocomplete-dropdown-container ul li:hover {
  background-color: #eee;
}

.company-selection-sec {
  background-color: #fff;
  box-shadow: 3px 10px 10px #00000029;
  left: 15px;
  max-width: 480px;
  position: absolute;
  text-align: left;
  top: 85px;
  width: 100%;
  z-index: 99999;
}

.company-selection-sec ul {
  height: 100%;
  list-style: none;
  margin: 0;
  max-height: 220px;
  overflow-y: scroll;
  padding: 0;
}

.company-selection-sec ul li {
  display: block;
  margin: 5px 0;
  padding: 5px 20px;
  width: 100%;
  cursor: pointer;
}

.company-selection-sec ul li:hover {
  background-color: #eee;
}

.serch_btn1 {
  background-color: #e7c7d2;
  border: none;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 7px;
  margin-top: 34px;
  text-align: center;
}

.type_btn1 {
  background-color: #a4c1bf;
  border: none;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  padding: 10px 50px;
  border-radius: 7px;
  margin-top: 34px;
  text-align: center;
}
.check-box {
  height: 25px;
  width: 25px;
  margin-top: 3px;
}
.checkbox-container {
  display: flex;
  align-items: start;
}
.checkbox-label {
  margin-left: 18px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.checkbox-span {
  font-weight: bold;
  font-size: 17px;
}
.confirmation {
  display: flex;
  gap: 10px;
}
.confirmation .confirmation_input {
  margin: 0;
  padding: 0;
  height: 30px;
}
