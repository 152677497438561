.claim_container {
  width: 100%;
  max-width: 1400px !important;
}
.claim_container .main-layout.d-flex {
  width: 100%;
  background: #fff;
  padding: 40px;
  margin: 40px auto;
  border: 1px solid rgb(225, 225, 225);
}
.claim_container .main {
  width: 85%;
  margin: 0 auto;
  padding: 0;
}
.claim_container .main h2 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  padding-bottom: 10px;
  padding-top: 10px;
}
.claim_container .details_box {
  background: #fff;
  padding: 10px 15px;
  display: flex;
}
.claim_container .details_box > div {
  padding: 0 10px;
}
.claim_business > div {
  width: 100%;
  display: flex;
  align-items: center;
}
.claim_business h1 {
  color: #000;
  opacity: 0.9;
  font-size: 22px;
  font-weight: 600;
  margin-left: 20px;
}
.claim_business p {
  color: #e13773;
  font-style: italic;
  background: #fbe8ef;
  font-size: 13px;
  margin-bottom: 0;
  padding: 7px 17px;
  margin-left: 20px;
  border-radius: 5px;
}
.claim_business {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
}
.clamin_business_left .steps {
  width: 100%;
  text-align: right;
  color: #707070;
  font-size: 16px;
  font-weight: 600;
  margin-left: 7px;
}
.clamin_business_left .steps a {
  color: #04726d;
  margin-left: 7px;
  font-weight: 600;
}
.clamin_business_left button {
  background: #143e3c;
  border: 0px;
  color: #fff;
  padding: 0px 40px;
  border-radius: 5px;
  height: 48px;
  margin-top: 40px;
}
.left {
  float: left;
}

form input,
form select {
  height: 48px;
  border: 1px solid #707070 !important;
  border-radius: 5px;
  opacity: 0.6;
  font-weight: 600;
  padding-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
form input:focus,
form input:hover {
  outline: none;
}
form label,
form label input {
  width: 100%;
  color: #000;
  font-size: 14px;
  font-weight: 600;
}
.devide {
  columns: 2;
  width: 100%;
}
.tel {
  position: relative;
}
.tel input {
  padding-left: 70px;
}
.tel:before {
  content: "";
  background-image: url("../images/flag.png");
  background-size: 100%;
  padding: 15px;
  position: absolute;
  top: 56%;
  left: 2%;
  background-repeat: no-repeat;
  opacity: 1;
  z-index: 999;
}
.tel:after {
  content: "";
  background-image: url("../images/drop.png");
  background-size: 100%;
  padding: 8px;
  position: absolute;
  top: 60%;
  right: unset;
  background-repeat: no-repeat;
  left: 50px;
}
.checkbox input {
  float: left;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin: 0px;
  color: #000;
  opacity: 0.6;
  border-radius: 5px;
}
.checkbox {
  position: relative;
  padding-top: 7px;
  margin-right: 20px;
  width: auto;
}
.checkbox span {
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}
.right_panel h3 {
  color: #04726d;
  font-size: 13px;
  font-style: italic;
  background: #e3efee;
  width: auto;
  float: left;
  margin-top: 10px;
  padding: 5px 10px;
}
.right_panel h3:before {
  content: "";
  background: url("../images/info.png");
  padding: 7px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin-right: 8px;
}
.right_panel h2 {
  padding-top: 25px;
}
.upload:after {
  content: "Drop File Here Or Browse";
  position: absolute;
  top: 50%;
  right: 10px;
  color: #8f8f94;
  font-size: 13px;
}
.upload {
  position: relative;
}
.upload input {
  font-size: 0px;
  cursor: pointer;
}
div.mm-dropdown {
  min-height: 48px;
  border: 1px solid #70707075;
  border-radius: 5px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}
div.mm-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 0;
}
div.mm-dropdown div.textfirst img.down {
  float: right;
  width: 16px;
  margin-top: 5px;
  position: relative;
  right: 16px;
  top: 14px;
}
div.mm-dropdown ul li:last-child {
  border-bottom: 0;
}
div.mm-dropdown ul li {
  display: none;
  cursor: pointer;
  padding-left: 10px;
  margin-bottom: 10px;
}
div.mm-dropdown ul li.main {
  display: block;
}
.dropdown_img img {
  margin-right: 15px;
  width: 50px;
  object-fit: cover;
  height: 50px;
  border-radius: 5px;
}
.dropdown_img {
  display: flex;
  line-height: normal;
  align-items: center;
  padding: 10px;
}
.dropdown_data h5 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.dropdown_data span {
  color: #000;
  opacity: 0.6;
  font-size: 13px;
}
.textfirst {
  width: 100%;
  height: 100%;
  padding: 0px !important;
  line-height: 48px;
}
.textfirst .select_tx {
  width: 100%;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.6;
  padding-left: 15px;
}
.claim_popup,
.request_submit {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #0000001a;
  top: 0;
  display: none;
  z-index: 9999;
}
.popup_data,
.request_submit_popup {
  background: #fff;
  top: 50%;
  position: absolute;
  left: 50%;
  width: 100%;
  max-width: 650px;
  padding: 20px !important;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 50px #00000029;
  border: 1px solid #70707057;
  border-radius: 5px;
}
#claimmodal h1 {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
}
#claimmodal p {
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 16px;
}
#claimmodal p a {
  color: #04726d;
  text-decoration: none;
}
#claimmodal .receive_email_inner label.checkbox {
  padding-top: 0;
}
.receive_email_inner {
  border: 1px solid #707070;
  padding: 15px 20px;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
}
.receive_email_inner .submit input {
  background: #143e3c;
  width: auto;
  float: right;
  padding: 0 40px;
  opacity: 1;
  color: #fff;
  border: 0px !important;
  box-shadow: none;
  font-size: 16px;
  font-weight: 600;
}
.popup_data .checkbox span {
  margin-left: 20px;
  font-size: 18px;
}
#claimmodal .margin input {
  margin-left: 50px;
  opacity: 1;
  width: calc(100% - 70px);
}
.popup_data .checkbox input {
  margin-top: 3px;
}
#claimmodal .devide_new {
  margin-top: 10px;
  display: none;
}
#claimmodal .receive_email_inner.show_data .devide_new {
  display: flex;
}
.request_submit {
  text-align: center;
}
.req_submited_popup h1 {
  font-size: 36px;
  font-weight: 600;
  color: #000;
  padding: 20px 0;
}
.req_submited_popup p {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  width: 100%;
  max-width: 400px;
  margin: 0 auto 30px;
}
.req_submited_popup button {
  height: 48px;
  background: #143e3c;
  padding: 0 60px;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  border: 0px;
}
body .request_submit .request_submit_popup {
  padding: 50px;
}

.claim_container .details_box input {
  font-size: 14px;
  background-color: #fff;
  border: solid 1px #707070 !important;
  opacity: 0.6;
}

.claim_container .details_box input:focus {
  border: 1px solid #0b0202 !important;
  box-shadow: 0 0 5pt 0.5pt #d3d3d3;
}
.claim_form {
  background-color: #fff;
}
.admin_claim_top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}
.admin_claim_top.steps {
  color: #000;
  font-weight: 600;
  display: flex;
  gap: 8px;
  padding: 0px 135px;
}
.admin_claim_top.steps .gray {
  color: #000;
  font-weight: 400;
  text-decoration: none;
}
.admin_claim_top.steps span {
  text-decoration: underline;
  font-weight: 600;
  color: #000;
}
.admin_claim_top.steps_row .steps a {
  color: #000;
  text-decoration: none;
  font-weight: 600;
  margin-left: 3px;
}
/* .claim_container .details_box .upload input {
  font-size: 0;
} */

#claimmodal {
  width: 100%;
  max-width: 650px;
}

.req_submited_popup {
  width: 100%;
  max-width: 650px;
  text-align: center;
}

.req_submited_popup .modal-header {
  display: none;
}

.req_submited_popup .modal-body {
  padding: 25px 25px 0;
}

.req_submited_popup .modal-footer {
  border-top: 0;
  padding-top: 0;
  justify-content: center;
}

.browse_input {
  padding: 10px;
  padding-right: 185px;
}

#otpmodal {
  max-width: 600px;
}

#otpmodal .modal-title {
  width: 100%;
}

#otpmodal .modal-footer {
  border-top: 0;
}

#otpmodal .modal-body {
  padding-top: 0;
}

#otpmodal .receive_email_inner {
  border: 0;
  width: 100%;
  max-width: 220px;
  margin: 0 auto;
}

#otpmodal .submit {
  display: block;
}

#otpmodal .submit input {
  float: none;
  width: 100%;
  padding: 0 10px;
}

#otpmodal .receive_email_inner input::-webkit-outer-spin-button,
#otpmodal .receive_email_inner input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#otpmodal .receive_email_inner input[type="number"] {
  -moz-appearance: textfield;
}

#claimmodal .modal-footer {
  border-top: 0;
}

.resend-otp {
  text-align: center;
  cursor: pointer;
  color: #143e3c;
  text-decoration: underline;
  margin-top: 10px;
}

.resend-otp:hover {
  text-decoration: none;
}

#claimmodal .receive_email_inner.show_data .devide_new label.margin {
  position: relative;
}

.suffixData {
  position: absolute;
  right: 35px;
  top: 24px;
}

.claim_container .devide {
  display: flex;
  gap: 20px;
}

.claim_container .clamin_business_left .steps {
  color: #04726d;
}

.claim_container .clamin_business_left .steps .gray {
  color: #9c9c9c;
}

.left_panel .autocomplete-dropdown-container {
  top: 130px !important;
}

.left_panel .form-group textarea.form-control {
  font-size: 14px;
  border: 1px solid #707070 !important;
  margin: 10px 0;
  opacity: 0.6;
}

.companyname-clear button {
  border: 0;
  color: #04726d;
}

.form-group textarea#streetNo {
  background-color: #fff;
}

.claim_business .steps span {
  text-decoration: underline;
  font-weight: 700;
}

.claim_business .steps a {
  color: #04726d;
  margin-left: 7px;
  font-weight: 600;
  text-decoration: none;
}

.claim_business .steps .gray {
  color: #9c9c9c;
  text-decoration: none;
}

.devide .form-select:disabled {
  background-color: #fff;
  opacity: 0.6;
}

.companyname-clear button:focus-visible {
  outline: none !important;
}

.details_box #companybasedSearch {
  padding-right: 55px;
}
.errormsg {
  color: red;
  font-size: 12px;
}
.devide_subsection {
  width: 100%;
}
#claim_loader .modal-content {
  background-color: transparent;
  border: none;
}
#claim_loader .loading-wrapper {
  min-height: 90vh;
}

.clamin_business_center {
  text-align: center;
  margin-top: 25px;
}

.clamin_business_center p {
  max-width: 700px;
  margin: 0 auto;
  font-weight: 600;
  margin-top: 25px;
  font-size: 17px;
}

.clamin_business_center .button {
  margin-top: 0;
}

.not_available-label {
  display: inline-block;
  background-color: #eee;
  color: #000;
  padding: 5px 10px;
  position: absolute;
  top: 10px;
  right: 22px;
}

@media screen and (max-width: 1024px) {
  .claim_container .main {
    width: 100%;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .claim_business {
    display: block;
  }
  .clamin_business_left .steps {
    text-align: left;
    width: auto;
    margin-left: 0;
  }
  .claim_business .clamin_business_left + .clamin_business_left {
    margin-top: 15px;
  }
}

@media screen and (max-width: 600px) {
  .claim_business > div {
    display: block;
  }
  .claim_business p {
    display: inline-block;
  }
  .claim_container .main-layout.d-flex {
    padding: 25px;
    margin: 20px auto 0;
  }
  .devide {
    columns: 1;
  }
  .clamin_business_left .steps a {
    margin-left: 4px;
  }
  .claim_container .devide {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .claim_container .main-layout.d-flex {
    padding: 15px;
  }
  #claimmodal .receive_email_inner.show_data .devide_new {
    flex-direction: column;
  }
  .popup_data .margin input {
    width: 100%;
    margin-left: 0;
  }
}

.manager-name-label {
  margin-top: 16.5px;
}

.company-contact-detail-area ul {
  margin-top: 20px;
  padding: 0.7rem;
  margin-bottom: 0px;
}
.company-contact-detail-area ul li {
  position: relative;
  padding-left: 23px;
  padding-bottom: 1px;
  color: black;
  font-size: 17px;
}
.company-contact-detail-area ul li::before {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 8px;
  width: 7px;
  height: 7px;
  background: black;
  border-radius: 50px;
}
.modal-header .close_modal_box .close_icon {
  float: right;
  margin-top: -8px;
}
.modal-title {
  width: 100%;
}