.dashboard-card-number-color {
  color: #143e3c;
}
.card-2 {
  margin-left: 16px;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(
    0.25turn,
    rgb(255, 99, 177),
    rgba(255, 0, 128, 0.02),
    rgba(56, 2, 155, 0)
  );
  border-image-slice: 100%;
  border-image-slice: 1;
  width: 100%;
  padding-bottom: 8px;
}
.select-box {
  margin-bottom: 23px;
  margin-right: 5px;
  text-align: end;
  font-size: 14px;
}
#timeFrame {
  width: 100px;
  height: 28px;
  text-align: center;
  background-color: white;
  border: 0.1px solid;
}
.dashboard_top_card {
  display: flex;
  justify-content: space-between;
}
.dashboard_top_card span,
.dashboard_bottom_card span {
  border: 0.5px solid lightgray;
  padding: 8px;
  background-color: #f5f6f8;
  height: 44px;
  border-radius: 4px;
}
.card-1 {
  width: 100%;
  max-width: 200px;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(
    0.25turn,
    rgb(255, 99, 177),
    rgba(255, 0, 128, 0.02),
    rgba(56, 2, 155, 0)
  );
  border-image-slice: 100%;
  border-image-slice: 1;
  padding-bottom: 8px;
}
.dashboard_line_Chart canvas {
  width: 100% !important;
  max-width: 1500px;
  height: auto !important;
}
.dashboard_bottom_card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* padding-inline: 10px; */
  align-items: center;
}

.coomingSoon-btn {
    background-color: #f5f6f8;
    padding: 5px 10px;
    display: inline-block;
    border: 0.5px solid lightgray;
    width: auto;
    max-width: 150px;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    right: 18px;
    bottom: 15px;
    font-size: 12px;
}
