.container {
  max-width: 100%;
  padding: 0 20px;
}
.admin_login {
  max-width: 1400px;
  /* background: #f5f6f8; */

  padding: 40px;
  margin: 40px auto;
  border: 1px solid rgb(225, 225, 225);
  background-color: #fff;
}
.top-btn-links {
  max-width: 1400px;
  margin: 25px auto -15px;
}
.top-btn-links a {
  color: #e13773;
  font-weight: 600;
  position: relative;
  padding-left: 20px;
}
.top-btn-links a:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../images/right-arrow.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 15px;
  transform: rotate(-180deg);
}
.admin_login_inner {
  display: flex;
  background: #fff;
  border-radius: 5px;
  justify-content: center;
  align-items: flex-start;
}
.admin_login h1 {
  font-size: 48px;
  font-weight: 700;
  color: #000;
  opacity: 0.9;
}
.admin_login .right_login_panel p {
  font-size: 20px;
  color: #000;
  font-weight: 500;
  padding: 10px 0;
}
.right_login_panel label,
.right_login_panel label input {
  width: 100%;
  font-size: 16px;
  color: #000;
  margin: 3px 0;
  font-weight: 500;
}
.right_login_panel label input {
  height: 48px;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  font-weight: 600;
  padding-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.right_login_panel {
  padding: 0 40px;
}
.input_flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.input_flex label {
  width: 100%;
}
.input_flex .checkbox::after {
  content: "";
  width: 24px;
  height: 24px;
  border: 1px solid #707070;
  position: absolute;
  left: 0;
  top: 7px;
  border-radius: 5px;
}
.input_flex .checkbox input {
  float: left;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin: 0px;
  border-radius: 5px;
}
.input_flex .checkbox {
  position: relative;
  padding-top: 7px;
}
.input_flex .checkbox span {
  margin-left: 10px;
  cursor: pointer;
}
.forget span {
  color: #04726d;
  opacity: 1;
  font-weight: 600;
  text-decoration: underline;
}
.right_login_panel .forget {
  text-align: right;
}
.right_login_panel .submit {
  margin-top: 15px;
}
.right_login_panel .submit input {
  background: #143e3c;
  color: #fff;
  border: 0;
}
.right_login_panel .claim {
  text-align: center;
  margin-top: 15px;
}
.right_login_panel .claim a {
  color: #e13773;
  opacity: 1;
  font-weight: 600;
  padding-left: 7px;
}
.claim span {
  color: #000;
  opacity: 0.6;
}
.right_login_panel label input:hover,
.right_login_panel label input:focus {
  outline: none;
}
.right_login_panel .show_pass {
  position: absolute;
  top: 50%;
  right: 15px;
}
.right_login_panel .password {
  position: relative;
}
::-webkit-input-placeholder {
  color: #000;
  opacity: 0.6;
}
::-moz-placeholder {
  color: #000;
  opacity: 0.6;
}
:-ms-input-placeholder {
  color: #000;
  opacity: 0.6;
}
:-moz-placeholder {
  color: #000;
  opacity: 0.6;
}

.right_login_panel .submit button {
  background: #143e3c;
  color: #fff;
  border: 0;
  width: 100%;
  height: 48px;
}

/* .new-company {
  padding-top: 20px;
} */

.new-company .claim a {
  padding-left: 0;
}

@media screen and (max-width: 1024px) {
  .right_login_panel {
    padding: 0 25px;
  }
}

@media screen and (max-width: 768px) {
  .admin_login_inner {
    display: block;
  }
  #admin_bar {
    display: block;
  }
  .right_login_panel {
    padding: 30px 40px;
  }
}

@media screen and (max-width: 600px) {
  .admin_login {
    margin: 20px auto;
    padding: 25px;
  }
  .right_login_panel {
    padding: 30px;
  }
}

@media screen and (max-width: 480px) {
  .input_flex {
    display: block;
  }
  .right_login_panel .forget {
    text-align: left;
  }
  .admin_login {
    margin: 10px auto 0;
    padding: 0;
  }
  .right_login_panel {
    padding: 20px 15px;
  }
}
