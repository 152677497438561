.container {
  max-width: 100%;
  padding: 0 20px;
}
.header_container {
  max-width: 100%;
  padding: 0 20px;
}
#signup_bar .admin_login {
  max-width: 1400px;
  background: #fff;
  padding: 40px;
  margin: 40px auto;
  border: 1px solid rgb(225, 225, 225);
}
#signup_bar .admin_login_inner {
  display: flex;
  background: #fff;
  border-radius: 5px;
  justify-content: center;
  align-items: flex-start;
}
#signup_bar .admin_login h1 {
  font-size: 30px;
  font-weight: 700;
  color: #000;
  opacity: 0.9;
}
#signup_bar .admin_login .right_login_panel p {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  opacity: 0.6;
  padding: 10px 0;
  width: 100%;
  max-width: 450px;
}
#signup_bar .right_login_panel label,
#signup_bar .right_login_panel label input {
  width: 100%;
  font-size: 16px;
  opacity: 0.6;
  color: #000;
  margin: 3px 0;
  font-weight: 600;
}
#signup_bar .right_login_panel label {
  color: #000;
  opacity: 1;
}
#signup_bar .right_login_panel label input {
  height: 48px;
  border: 1px solid #707070;
  border-radius: 5px;
  color: #000;
  opacity: 0.6;
  font-weight: 600;
  padding-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
#signup_bar .right_login_panel {
  padding: 0 60px;
}
#signup_bar .input_flex {
  display: flex;
  align-items: center;
}
#signup_bar .input_flex label {
  width: 100%;
}
#signup_bar .input_flex .checkbox input {
  float: left;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin: 0px;
  color: #000;
  opacity: 0.6;
  border-radius: 5px;
}
#signup_bar .input_flex .checkbox {
  position: relative;
  padding-top: 7px;
  margin-right: 50px;
  width: auto;
}
#signup_bar .input_flex .checkbox span {
  margin-left: 10px;
  cursor: pointer;
}
#signup_bar .forget span {
  color: #04726d;
  opacity: 1;
  font-weight: 600;
  text-decoration: underline;
}
#signup_bar .right_login_panel .forget {
  text-align: right;
}
#signup_bar .right_login_panel .submit {
  margin-top: 15px;
}
#signup_bar .right_login_panel .submit input {
  background: #143e3c;
  color: #fff;
  border: 0;
  opacity: 1;
}
#signup_bar .right_login_panel .claim {
  text-align: center;
}
#signup_bar .right_login_panel .claim a {
  color: #e13773;
  opacity: 1;
  font-weight: 600;
  padding-left: 7px;
}
#signup_bar .claim span {
  color: #000;
  opacity: 0.6;
}
#signup_bar .right_login_panel label input:hover,
#signup_bar .right_login_panel label input:focus {
  outline: none;
}
#signup_bar .right_login_panel .show_pass {
  position: absolute;
  top: 50%;
  right: 15px;
}
#signup_bar .right_login_panel .password {
  position: relative;
}
::-webkit-input-placeholder {
  color: #000;
  opacity: 0.6;
}
::-moz-placeholder {
  color: #000;
  opacity: 0.6;
}
:-ms-input-placeholder {
  color: #000;
  opacity: 0.6;
}
:-moz-placeholder {
  color: #000;
  opacity: 0.6;
}

#signup_bar .steps_row .steps,
.steps_row h1 {
  width: 100%;
}
#signup_bar .steps_row {
  display: flex;
  justify-content: center;
  align-items: center;
}
#signup_bar .steps {
  color: #000;
  font-weight: 600;
  display: flex;
  gap: 8px;
  padding: 0px 135px;
}
#signup_bar .steps .gray {
  color: #000;
  font-weight: 400;
  text-decoration: none;
}
#signup_bar .steps span {
  text-decoration: underline;
  font-weight: 600;
  color: #000;
}
#signup_bar .steps_row .steps a {
  color: #000;
  text-decoration: none;
  font-weight: 600;
  margin-left: 3px;
}

#signup_bar .right_login_panel select {
  height: 48px;
  border: 1px solid #707070;
  border-radius: 5px;
  color: #000;
  opacity: 0.6;
  font-weight: 600;
  padding-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-family: "nunito", Helvetica, Arial, Verdana, Tahoma, sans-serif;
}
#signup_bar .right_login_panel select option {
  font-family: "nunito", Helvetica, Arial, Verdana, Tahoma, sans-serif;
}
#signup_bar .right_login_panel .tel {
  position: relative;
}
#signup_bar .right_login_panel .tel input {
  padding-left: 70px;
}
#signup_bar .right_login_panel .tel:before {
  content: "";
  background-image: url("../images/flag.png");
  background-size: 100%;
  padding: 15px;
  position: absolute;
  top: 56%;
  left: 2%;
  background-repeat: no-repeat;
  opacity: 1;
  z-index: 999;
}
#signup_bar .right_login_panel .tel:after {
  content: "";
  background-image: url("../images/drop.png");
  background-size: 100%;
  padding: 8px;
  position: absolute;
  top: 60%;
  right: unset;
  background-repeat: no-repeat;
  left: 45px;
}

#signup_bar .input_flex .checkbox:after {
  border: 0;
}
.confirmation {
  display: flex;
  gap: 10px;
}
.confirmation .confirmation_input {
  margin: 0;
  padding: 0;
  height: 30px;
}

@media screen and (max-width: 1024px) {
  #signup_bar .right_login_panel {
    padding: 0 25px;
  }
}

@media screen and (max-width: 768px) {
  #signup_bar {
    display: block;
  }
  #signup_bar .admin_login_inner {
    display: block;
  }
  #signup_bar .right_login_panel {
    padding: 30px 40px;
  }
}

@media screen and (max-width: 600px) {
  #signup_bar .admin_login {
    margin: 20px auto;
    padding: 25px;
  }
  #signup_bar .right_login_panel {
    padding: 30px;
  }
  #signup_bar .steps_row {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  #signup_bar .admin_login {
    margin: 10px auto 0;
    padding: 0;
  }
  #signup_bar .right_login_panel {
    padding: 20px 15px;
  }
  #signup_bar .input_flex .checkbox {
    margin-right: 20px;
  }
}
