.staff_container .action_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.staff_container .staff_btn {
  padding: 6px 1px;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  border: none;
  font-size: 16px;
}
.edit_btn {
  background-color: #04726d;
  color: #fff;
}
.delete_btn {
  background-color: #e33773;
  color: #fff;
}
.staff_modal input {
  padding: 7px 10px;
}
.staff_modal label {
  color: #6c757d !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 10px;
}
.staff_modal .modal_file,
.staff_modal .modal_file img {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  border: 1px solid #ced4da;
  border-radius: 100%;
  height: 160px;
  width: 160px;
  object-fit: cover;
}

.staff_modal .modal_file svg {
  height: 21px !important;
  width: 21px !important;
}

.staff_modal .upload_icon_button:hover {
  background-color: transparent !important;
}
.staff_modal .modal_body {
  border-bottom: 1px solid #ced4da;
  margin: 0;
  padding: 30px 20px;
  width: 100%;
}
.staff_modal .staff_modal_btn {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  color: #fff;
}
.modal_file label {
  width: 100%;
  height: auto;
  min-height: 100%;
}
button:disabled,
button[disabled] {
  background-color: #a5b5b4;
  cursor: not-allowed !important;
}
