.notification_container .notify_btn {
  background-color: #04736d;
  color: #fff;
  font-weight: 500;
  text-align: center;
  border-color: #04736d !important;
  box-shadow: none !important;
  padding: 4px 10px;
}
.no_btn:hover {
  background-color: #04736d !important;
}
.notify_btn:hover,
.notify_btn.active {
  background-color: #035c57 !important;
}
.notification_container .save_btn {
  background-color: #097b75 !important;
}
.notification_container .save_btn:hover {
  background-color: #04736d !important;
}
.notification_container .cancel_btn {
  background-color: #e33572 !important;
}
.notification_container .cancel_btn:hover {
  background-color: #c32c5f !important;
}
